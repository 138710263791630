import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
  message,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { platforms } from '../../constants';
import { config, getSocialAvatarUrl, getSocialPageUrl } from '../../utils';
import DataSourceItem from './DataSourceItem';
import './PancakeConfig.css';

const PancakeConfig = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState();
  const [cosultants, setCosultants] = useState();
  const [loadingPages, setLoadingPages] = useState(false);
  const [loadingCosultants, setLoadingCosultants] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [mode, setMode] = useState('source');
  const [configs, setConfigs] = useState();
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/configs`,
          config
        );

        setConfigs(data.data);
      } catch (error) {
        message.error('Không lấy được dữ liệu cấu hình hệ thống CRM');
      }
    };

    const fetchPages = async () => {
      try {
        setLoadingPages(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/pancake/pages`
        );

        setDataSource({
          facebook: data?.data?.categorized?.activated.filter(
            (page) =>
              page.platform === 'facebook' &&
              !page.username?.toLowerCase().includes('trina') &&
              !page.name?.toLowerCase().includes('trina')
          ),
          instagram_official: data?.data?.categorized?.activated.filter(
            (page) => page.platform === 'instagram_official'
          ),
          tiktok: data?.data?.categorized?.activated.filter(
            (page) => page.platform === 'tiktok'
          ),
          all: data?.data?.categorized?.activated.filter(
            (page) =>
              !page.username?.toLowerCase().includes('trina') &&
              !page.name?.toLowerCase().includes('trina')
          ),
        });
      } catch (error) {
        console.log('error', error);
        message.error('Không lấy được danh sách trang Pancake');
      } finally {
        setLoadingPages(false);
      }
    };

    const fetchCosultants = async () => {
      try {
        setLoadingCosultants(true);

        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/cosultants`,
          config
        );

        setCosultants(data.data);
      } catch (error) {
        message.error('Không lấy được danh sách tư vấn viên CRM');
      } finally {
        setLoadingCosultants(false);
      }
    };

    const fetchUsers = async () => {
      try {
        setLoadingUsers(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/pancake/users`
        );

        setUsers(data?.data);
      } catch (error) {
        setIsTokenInvalid(true);
        message.error('Không lấy được danh sách người dùng Pancake');
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchConfigs();
    fetchPages();
    fetchCosultants();
    fetchUsers();
  }, []);

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const getPlatformData = (platform) => {
    const dataPlatform = platform ? dataSource?.[platform] : dataSource?.all;
    const filterdData = dataPlatform?.map((page) => ({
      ...page,
      avatar_url: getSocialAvatarUrl(page.id, page.platform),
      page_url: getSocialPageUrl(
        page.username?.replace('igo_', ''),
        page.platform
      ),
    }));

    return filterdData;
  };

  const renderDataSource = () => {
    return (
      <Tabs defaultActiveKey=''>
        {platforms?.map((item) => (
          <Tabs.TabPane
            tab={`${item.name} (${getPlatformData(item.alias)?.length || 0})`}
            key={item.alias}
          >
            <div
              className='data-source'
              style={{
                marginBottom: 20,
              }}
            >
              {getPlatformData(item.alias)?.map((page) => (
                <a href={page.page_url} target='_blank' rel='noreferrer'>
                  <DataSourceItem data={page} />
                </a>
              ))}
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    );
  };

  const handleChannelChange = (channelId, pageId) => {
    const newConfigs = { ...configs };
    const pageChannelMapping = newConfigs?.pancake?.page_channel_mapping;

    const isFound = pageChannelMapping?.find((mapObj) => {
      if (mapObj.pancake_page_id === pageId) {
        mapObj.crm_channel = channelId;
        return true;
      }
      return false;
    });

    if (!isFound) {
      pageChannelMapping.push({
        pancake_page_id: pageId,
        crm_channel: channelId,
      });
    }

    setConfigs(newConfigs);
  };

  const handleUserChange = (userId, cosId) => {
    const newConfigs = { ...configs };
    const pancakeUserMapping = newConfigs?.pancake?.pancake_user_mapping;

    const isFound = pancakeUserMapping?.find((mapObj) => {
      if (mapObj.crm_user_id === cosId) {
        mapObj.pancake_user_id = userId;
        return true;
      }
      return false;
    });

    if (!isFound) {
      pancakeUserMapping.push({
        pancake_user_id: userId,
        crm_user_id: cosId,
      });
    }

    setConfigs(newConfigs);
  };

  const handleNetworkChange = (e) => {
    const accessToken = e.target.value;
    setConfigs({
      ...configs,
      pancake: {
        ...configs.pancake,
        access_token: accessToken,
      },
    });
  };

  const handleSave = async () => {
    try {
      console.log(configs);
      setLoadingUpdate(true);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/configs`,
        configs,
        config
      );

      message.success('Lưu cấu hình thành công');
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  const renderContent = () => {
    switch (mode) {
      case 'source':
        return (
          <>
            {!loadingPages ? (
              <>
                {renderDataSource()}
                <div className='source-config'>
                  <Typography.Title level={5}>
                    Đồng bộ Pancake page - Kênh tiếp cận
                  </Typography.Title>
                  {isTokenInvalid && (
                    <Alert
                      showIcon
                      type='error'
                      message='Access Token không hợp lệ. Vui lòng kiểm tra lại trong mục "Kết nối".'
                    />
                  )}

                  {dataSource?.all?.map((page) => (
                    <>
                      <Space direction='horizontal' className='config-item'>
                        <div className='flex items-center config-page'>
                          <Avatar
                            size='small'
                            style={{
                              marginRight: '10px',
                            }}
                            src={
                              <img
                                style={{
                                  objectFit: 'contain',
                                }}
                                src={
                                  page.platform === 'facebook'
                                    ? '/facebook.png'
                                    : page.platform === 'instagram_official'
                                    ? '/instagram.png'
                                    : '/tiktok.png'
                                }
                                alt='avatar'
                              />
                            }
                          />
                          <Typography.Text>{page.name}</Typography.Text>
                        </div>
                        <Select
                          style={{
                            width: 200,
                          }}
                          allowClear
                          placeholder='Chọn kênh tiếp cận'
                          onChange={(value) =>
                            handleChannelChange(value, page.id)
                          }
                          defaultValue={
                            configs?.pancake.page_channel_mapping.find(
                              (config) => config.pancake_page_id === page.id
                            )?.crm_channel
                          }
                        >
                          {configs?.channels?.map((channel) => (
                            <Select.Option
                              key={channel._id}
                              value={channel.channel_name}
                            >
                              {channel.channel_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Space>
                      <br />
                    </>
                  ))}
                  <Button
                    hidden={isTokenInvalid}
                    style={{ marginTop: '10px' }}
                    type='primary'
                    loading={loadingUpdate}
                    onClick={handleSave}
                  >
                    Lưu cấu hình
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <Spin />
              </div>
            )}
          </>
        );
      case 'cosultant':
        return (
          <>
            {!loadingCosultants && !loadingUsers ? (
              <>
                <div className='cosultant-config'>
                  <Typography.Title level={5}>
                    Đồng bộ Tư vấn viên - Pancake user
                  </Typography.Title>

                  {isTokenInvalid && (
                    <Alert
                      showIcon
                      type='error'
                      message='Access Token không hợp lệ. Vui lòng kiểm tra lại trong mục "Kết nối".'
                    />
                  )}

                  {!isTokenInvalid &&
                    cosultants?.map((cos) => (
                      <>
                        <Space direction='horizontal' className='config-item'>
                          <div className='flex items-center config-page'>
                            <Avatar
                              size='large'
                              style={{
                                marginRight: '10px',
                              }}
                              icon={<UserOutlined />}
                              src={cos.avatar || null}
                            />
                            <Typography.Text>{cos.fullname}</Typography.Text>
                          </div>
                          <Select
                            style={{
                              width: 200,
                            }}
                            allowClear
                            placeholder='Người dùng Pancake'
                            onChange={(value) =>
                              handleUserChange(value, cos._id)
                            }
                            defaultValue={
                              configs?.pancake.pancake_user_mapping.find(
                                (config) => config.crm_user_id === cos._id
                              )?.pancake_user_id
                            }
                          >
                            {users?.map((user) => (
                              <Select.Option
                                key={user.user_id}
                                value={user.user_id}
                              >
                                {user.user.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Space>
                        <br />
                      </>
                    ))}
                  <Button
                    hidden={isTokenInvalid}
                    style={{ marginTop: '10px' }}
                    type='primary'
                    loading={loadingUpdate}
                    onClick={handleSave}
                  >
                    Lưu cấu hình
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <Spin />
              </div>
            )}
          </>
        );
      case 'network':
        return (
          <>
            {!loadingPages && !loadingUsers ? (
              <>
                <div className='cosultant-config'>
                  <Typography.Title level={5}>
                    Cấu hình Pancake Network
                  </Typography.Title>

                  <Form layout='vertical'>
                    <Form.Item
                      label={
                        <>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div
                              style={{
                                marginRight: 10,
                              }}
                            >
                              {t('Access Token')}
                            </div>
                            {isTokenInvalid ? (
                              <Tag icon={<CloseCircleOutlined />} color='error'>
                                Không hợp lệ
                              </Tag>
                            ) : (
                              <Tag
                                icon={<CheckCircleOutlined />}
                                color='success'
                              >
                                Hợp lệ
                              </Tag>
                            )}
                          </div>
                        </>
                      }
                    >
                      <Input.TextArea
                        rows={6}
                        defaultValue={configs?.pancake?.access_token}
                        onChange={handleNetworkChange}
                      />
                    </Form.Item>
                  </Form>

                  <Button
                    style={{ marginTop: '10px' }}
                    type='primary'
                    loading={loadingUpdate}
                    onClick={handleSave}
                  >
                    Lưu cấu hình
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <Spin />
              </div>
            )}
          </>
        );
      default:
        return '';
    }
  };

  return (
    <div className='pancake-config'>
      <Radio.Group
        onChange={handleModeChange}
        value={mode}
        style={{
          marginBottom: 8,
        }}
      >
        <Radio.Button value='source'>{t('dataSource')}</Radio.Button>
        <Radio.Button value='cosultant'>{t('consultant')}</Radio.Button>
        <Radio.Button value='network'>
          {t('network')}
          {isTokenInvalid && <Badge style={{ marginLeft: 5 }} count={1} />}
        </Radio.Button>
      </Radio.Group>
      {renderContent()}
    </div>
  );
};

export default PancakeConfig;
