import React from 'react';
import './DataSourceItem.css';
import { Avatar, Typography } from 'antd';
import { getSocialAvatarUrl } from '../../utils';

const DataSourceItem = ({ data }) => {
  const renderUsername = (platform, username) => {
    return (
      <div className='flex items-center'>
        <img
          src={
            platform === 'instagram_official'
              ? '/instagram.png'
              : platform === 'facebook'
              ? '/facebook.png'
              : platform === 'tiktok'
              ? '/tiktok.png'
              : '/logo-new.png'
          }
          width={15}
          alt=''
          style={{ marginRight: 5, borderRadius: 5 }}
        />
        <div className='source-username'>@{username?.replace('igo_', '')}</div>
      </div>
    );
  };

  return (
    <div className='source-item flex items-start'>
      <Avatar
        size='large'
        src={
          <img
            style={{
              objectFit: 'contain',
            }}
            src={
              data.avatar_url
                ? data.avatar_url
                : getSocialAvatarUrl(data.id, data.platform)
            }
            alt='avatar'
          />
        }
      />
      <div className='source-item-info'>
        <Typography.Text title={data.name} className='source-name'>
          {data.name}
        </Typography.Text>
        <Typography.Text title={data.username}>
          {renderUsername(data.platform, data.username)}
        </Typography.Text>
      </div>
    </div>
  );
};

export default DataSourceItem;
