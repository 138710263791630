import dayjs from 'dayjs';
import { t } from 'i18next';
import { REQUIRED_ATTRIBUTES_MAPPING } from '../constants';

const moment = require('moment');

export const config = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem('userInfo'))?.token
    }`,
  },
};

export const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export const isDateInMonthYear = (date, monthIndex, year) => {
  if (!date) return false;

  const compareDate = dayjs(new Date(year, monthIndex));
  return dayjs(date).isSame(compareDate, 'month');
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .replace(/\s/g, '')
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const FILTER_QUERY_MAPPING = {
  unRegistered: { is_register: false },
  studying: { study_status: 'Đang học' },
  reservation: { study_status: 'Bảo lưu' },
  drop: { study_status: 'Bỏ học' },
  L1L2: { customer_type: 'L1L2' },
  L2L3: { customer_type: 'L2L3' },
  T1: { t_type: 'T1' },
  T15: { t_type: 'T15' },
  T30: { t_type: 'T30' },
  T90: { t_type: 'T90' },
};

export const FILTER_KEY_TEXT_MAPPING = {
  name: 'recordName',
  expectedClass: 'recordExpectedClass',
  startDate: 'fromDate',
  endDate: 'toDate',
  channel: 'recordChannel',
  cosultant: 'consultant',
  yearOfBirth: 'recordDateOfBirthday',
  address: 'recordAddress',
  phone: 'recordPhone',
  studyStatus: 'studyStatus',
  status: 'recordStatus',
  source: 'dataSource',
  tuitionStatus: 'tuitionStatus',
  cusomerType: 'customerType',
  level: 'recordClassification',
  facility: 'recordFacility',
  isRegister: 'recordRegistered',
  email: 'recordEmail',
};

export const SOURCE_FILTER_MAPPING = {
  1: 'THALIC EDU',
  2: 'CRM',
  3: 'PANCAKE',
};

export const CUSOMER_TYPE_FILTER_MAPPING = {
  standard: 'standardCustomer',
  combo: 'comboCustomer',
  'level up': 'levelUpCustomer',
  L1L2: 'L1L2',
  L2L3: 'L2L3',
};

export const getFilterValue = (key, value) => {
  switch (key) {
    case 'source':
      return SOURCE_FILTER_MAPPING[value];
    case 'cusomerType':
      return CUSOMER_TYPE_FILTER_MAPPING[value];
    case 'isRegister':
      return value ? 'yesFilter' : 'noFilter';
    default:
      return value;
  }
};

export const getFilterTags = (filters) => {
  const cloneFilters = { ...filters };

  delete cloneFilters.page;
  delete cloneFilters.limit;

  const formattedFilters = {};
  Object.entries(cloneFilters).forEach(
    ([key, value]) =>
      (formattedFilters[key] = {
        transformedKey: FILTER_KEY_TEXT_MAPPING[key],
        value: getFilterValue(key, value),
      })
  );

  return formattedFilters;
};

export const LEVEL_ENUM = [
  'L1',
  'L2',
  'L3',
  'L4',
  'L5',
  'L6',
  'L7',
  'L8',
  'L9',
  'L10',
  'L11',
  'L12',
];

export const getCurrentMonthYearAndBefore = () => {
  const now = new Date();
  const monthBefore = now.getMonth() === 0 ? '12' : now.getMonth();
  const yearBefore =
    now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
  return {
    currentMonth: now.getMonth() + 1,
    monthBefore,
    currentYear: now.getFullYear(),
    yearBefore,
  };
};

export const getBase64FromFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const getSocialAvatarUrl = (pageId, platform) => {
  switch (platform) {
    case 'facebook':
      return `https://graph.facebook.com/${pageId}/picture?type=large&width=800&height=800`;
    default:
      return '/logo-new.png';
  }
};

export const getSocialPageUrl = (pageUsername, platform) => {
  switch (platform) {
    case 'instagram_official':
      return `https://www.instagram.com/${pageUsername}`;
    case 'facebook':
      return `https://www.facebook.com/${pageUsername}`;
    case 'tiktok':
      return `https://www.tiktok.com/@${pageUsername}`;
    default:
      return '/';
  }
};

export const potentials = [
  'Không tiềm năng',
  'Tiềm năng ít (<50%)',
  'Có tiềm năng',
];

export const getMismatchRecordData = (record) => {
  const mismatchData = [];
  if (record.is_register === true) {
    Object.entries({
      ...REQUIRED_ATTRIBUTES_MAPPING.unregistered,
      ...REQUIRED_ATTRIBUTES_MAPPING.registered,
    }).forEach(([key, value]) => {
      if (!record[key]) mismatchData.push(t(value));
    });
  } else {
    Object.entries(REQUIRED_ATTRIBUTES_MAPPING.unregistered).forEach(
      ([key, value]) => {
        if (!record[key]) mismatchData.push(t(value));
      }
    );
  }
  return mismatchData;
};

export const getTableRowClassName = (record) => {
  const recordWarnings = getRecordWarning(record);
  if (recordWarnings.length) return 'data-warning';

  if (record.callback && dayjs(record.callback).isToday())
    return 'data-callback';

  if (record.is_register) return 'data-registered';

  return '';
};

export const getRecordWarning = (record) => {
  const warnings = [];
  // type 1: single text
  // type 2: array of single text
  // type 3: array of object

  // khach bi lang quen
  if (
    record.is_register &&
    !record.joined_class &&
    moment().diff(record.updatedAt, 'hours') >= 48
  ) {
    warnings.push({
      type: 1,
      text: 'CHƯA PHÂN LỚP',
      color: '#ff7979',
    });
  }

  const mismatchData = getMismatchRecordData(record);
  if (mismatchData.length) {
    warnings.push({
      type: 2,
      text: 'THIẾU THÔNG TIN',
      data: mismatchData,
      color: '#f0932b',
    });
  }

  if (
    record.study_status === 'Đã kết thúc' &&
    record.isKhachHocLen === undefined &&
    moment().diff(record.updatedAt, 'hours') >= 120
  ) {
    warnings.push({
      type: 2,
      text: 'CHƯA UPSALE',
      data: ['Khách đã tổng kết, đã học xong nhưng chưa tick Level up'],
      color: '#e056fd',
    });
  }

  const dataForgot = [];
  if (
    record.joined_class &&
    record.study_status === 'Chưa học' &&
    moment().diff(record.updatedAt, 'hours') >= 72
  ) {
    dataForgot.push(`Khách đã phân lớp nhưng chưa khai giảng`);
  }

  if (
    record.joined_class &&
    record.study_status === 'Đang học' &&
    moment().diff(record.updatedAt, 'hours') >= 72
  ) {
    dataForgot.push(`Khách đang trong thời gian KG-TK`);
  }

  if (dataForgot.length) {
    warnings.push({
      type: 2,
      text: 'QUÊN CHĂM SÓC',
      data: dataForgot,
      color: '#ff7979',
    });
  }

  // phan loai sai
  const wrongLevels = [];
  const currentValue = record.level || 'Chưa nhập';

  if (
    ['Thuê bao/sai số', 'Chưa phản hồi'].includes(record.status) &&
    !record.isKhachHocLen &&
    record.level !== 'L1'
  ) {
    wrongLevels.push({
      title: `Contact nhận được (L1)`,
      currentValue,
    });
  }

  if (
    ['Không nghe máy', 'Hẹn gọi lại', 'Không add Zalo'].includes(
      record.status
    ) &&
    !record.isKhachHocLen &&
    record.level !== 'L2'
  ) {
    wrongLevels.push({
      title: `Data để lại SĐT (L2)`,
      currentValue,
    });
  }

  if (
    [
      'Đang xem thông tin',
      'Không phản hồi',
      'Không có nhu cầu',
      'Hỏi giùm người khác',
    ].includes(record.status) &&
    !record.isKhachHocLen &&
    record.level !== 'L3'
  ) {
    wrongLevels.push({
      title: `Tiếp cận, tư vấn khách (L3)`,
      currentValue,
    });
  }

  if (
    [
      'Đang chọn lịch',
      'Băn khoăn học phí',
      'Không ở HN, muốn học off',
      'Tư vấn Zalo',
      'Phân vân onl, off',
      'Hẹn tháng sau, ra Tết...',
      'Không xếp được lịch',
      'Muốn học thử',
    ].includes(record.status) &&
    !record.isKhachHocLen &&
    record.level !== 'L4'
  ) {
    wrongLevels.push({
      title: `Giới thiệu khoá học (L4)`,
      currentValue,
    });
  }

  if (
    ['Không làm test'].includes(record.status) &&
    !record.isKhachHocLen &&
    record.level !== 'L5'
  ) {
    wrongLevels.push({
      title: `Test giọng (L5)`,
      currentValue,
    });
  }
  if (
    ['Chuẩn bị ĐK'].includes(record.status) &&
    !record.isKhachHocLen &&
    record.level !== 'L6'
  ) {
    wrongLevels.push({
      title: `Đăng ký khoá học (L6)`,
      currentValue,
    });
  }

  if (
    record.is_register &&
    record.tuition_status === 'Còn nợ' &&
    record.study_status !== 'Đang học' &&
    !record.isKhachHocLen &&
    record.level !== 'L7'
  ) {
    wrongLevels.push({
      title: `Học phí còn nợ (L7)`,
      currentValue,
    });
  }

  if (
    record.is_register &&
    record.tuition_status === 'Đã hoàn thành' &&
    record.study_status !== 'Đang học' &&
    !record.isKhachHocLen &&
    record.level !== 'L8'
  ) {
    wrongLevels.push({
      title: `Học phí hoàn thành (L8)`,
      currentValue,
    });
  }

  if (
    record.is_register &&
    record.study_status === 'Đang học' &&
    !record.isKhachHocLen &&
    record.level !== 'L9'
  ) {
    wrongLevels.push({
      title: `Khách tham gia khoá học (L9)`,
      currentValue,
    });
  }

  if (
    record.study_status === 'Đã kết thúc' &&
    !record.isKhachHocLen &&
    record.level !== 'L10'
  ) {
    wrongLevels.push({
      title: `Đã tốt nghiệp (L10)`,
      currentValue,
    });
  }

  if (record.isKhachHocLen && record.level !== 'L11') {
    wrongLevels.push({
      title: `Khách học lên (L11)`,
      currentValue,
    });
  }

  if (wrongLevels.length) {
    warnings.push({
      type: 3,
      text: 'PHÂN LOẠI SAI',
      data: wrongLevels,
      color: '#706fd3',
    });
  }

  return warnings;
};
